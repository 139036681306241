<template>
  <a-select
    allowClear
    :placeholder="$t(`请选择仓库`)"
    show-search
    label-in-value
    option-filter-prop="children"
    style="width: 100%"
    :filter-option="filterOption"
    @change="handleChange"
  >
    <a-select-option v-for="item in warehouseList" :key="item.warehouseCode + ''">
      {{ item.warehouseName }}
    </a-select-option>
  </a-select>
</template>

<script>
import api from '@common/api'
import http from '@common/http'

export default {
  mounted() {
    this.searchWarehouseList()
  },
  data() {
    return {
      key: '',
      warehouseList: [],
      count: 0,
      choosedData: null,
      value: { key: '' },
    }
  },
  props: ['id', 'placeholder', 'defaultWarehouse', 'containsdeleted'],
  computed: {
    _selfPlaceholder() {
      return '--' + this.$t('请选择仓库') + '--'
    },
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    searchWarehouseList() {
      var _self = this
      console.log('仓库请求')
      http({
        instance: _self,
        url: api.getAllWarehouseList,
        type: 'post',
        data: {
          data: {
            key: '',
            containsDeleted: _self.containsdeleted,
          },
        },
        success(data) {
          console.log(data.result)
          _self.$set(_self, 'warehouseList', data.result)
        },
      })
    },
    handleChange(data) {
      if (data) {
        this.value = data
        this.choosedData = {
          warehouseCode: data.key,
          warehouseName: data.label,
        }
      } else {
        this.choosedData = {
          warehouseCode: '',
          warehouseName: '',
        }
      }
    },
  },
  watch: {
    choosedData() {
      this.$emit('selected', this.choosedData || {})
    },
    defaultWarehouse: {
      handler(val) {
        if (val != undefined && val != {} && !$.isEmptyObject(val)) {
          this.key = val.warehouseName
          this.value = { key: val.warehouseCode, label: val.warehouseName }
        } else {
          this.key = ''
          this.value = { key: '' }
          this.choosedData = ''
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
