<template>
  <div class="mul-unit-input">
    <span v-if="showList.length === 0">{{ amount }}</span>
    <template v-else>
      <span>{{ PAN }}</span>
      <span v-for="(item, index) in showList" :key="index">
        <template v-if="item.showAmount">{{ item.showAmount }}{{ item.unitNameFull }}</template>
      </span>
      <div v-if="list.length > 1 && amount">({{ showBaseNum }})</div>
    </template>
  </div>
</template>

<script>
import deepClone from '@/common/deepClone'
export default {
  props: {
    amount: {},
    amountString: {
      type: String,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showList: [],
    }
  },
  computed: {
    PAN() {
      //正负号
      if (this.amountString) {
        return +this.amountString < 0 ? '-' : '+'
      }
      return ''
    },
    showBaseNum() {
      let item = this.list.find((elem) => elem.baseUnit) || {}
      let str = this.amount + item.unitNameFull
      if (this.PAN) {
        str = this.PAN + str
      }
      return str
    },
  },
  methods: {
    getShowList(list) {
      let lessAmount = this.amount
      if (lessAmount === 0 || isNaN(this.amount)) {
        this.showList = []
        return
      }
      list.sort((a, b) => b.conversionRate - a.conversionRate)
      this.showList = list.map((item) => {
        let num = Math.floor(lessAmount / item.conversionRate)
        lessAmount -= item.conversionRate * num
        return {
          ...item,
          showAmount: num,
        }
      })
    },
  },
  watch: {
    list: {
      handler(newVal) {
        this.getShowList(deepClone(newVal))
      },
      immediate: true,
    },
    amount: {
      handler() {
        this.getShowList(deepClone(this.list))
      },
    },
  },
}
</script>

<style scoped></style>
